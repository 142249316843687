@if (complaint || showSection) {
    <div>
        @if (course?.complaintsEnabled ?? false) {
            <p>
                @if (!isExamMode && remainingNumberOfComplaints >= 0) {
                    <span
                        [jhiTranslate]="'artemisApp.complaint.' + (exercise.teamMode ? 'descriptionTeamExtended' : 'descriptionExtended')"
                        [translateValues]="{ maxComplaintNumber: course?.maxComplaints ?? 0, allowedComplaints: remainingNumberOfComplaints }"
                    ></span>
                }
                @if (isExamMode) {
                    <span [jhiTranslate]="'artemisApp.complaint.descriptionExam'"></span>
                }
                @if (!isExamMode) {
                    <fa-icon [icon]="faInfoCircle" title="{{ 'artemisApp.complaint.info' | artemisTranslate }}" class="info-icon" />
                }
            </p>
        }
        @if (isCorrectUserToFileAction && !complaint) {
            <div class="mt-4">
                @if (isExamMode || (course?.maxComplaints && course!.maxComplaints! > 0)) {
                    <button
                        id="complain"
                        class="btn btn-primary"
                        [class.not-allowed]="(!isExamMode && remainingNumberOfComplaints === 0) || !timeOfComplaintValid"
                        (click)="formComplaintType = ComplaintType.COMPLAINT"
                        [disabled]="(!isExamMode && remainingNumberOfComplaints === 0) || !timeOfComplaintValid"
                        title="{{
                            (!isExamMode && remainingNumberOfComplaints === 0) || !timeOfComplaintValid
                                ? ('artemisApp.complaint.complaintNotAllowedTooltip' | artemisTranslate)
                                : ''
                        }}"
                        jhiTranslate="artemisApp.complaint.moreInfo"
                    ></button>
                }
                @if (!isExamMode && course?.requestMoreFeedbackEnabled) {
                    <button
                        class="btn btn-primary ms-1"
                        [class.not-allowed]="!timeOfFeedbackRequestValid"
                        (click)="formComplaintType = ComplaintType.MORE_FEEDBACK"
                        [disabled]="!timeOfFeedbackRequestValid"
                        title="{{ !timeOfFeedbackRequestValid ? ('artemisApp.moreFeedback.notAllowedTooltip' | artemisTranslate) : '' }}"
                        jhiTranslate="artemisApp.moreFeedback.button"
                    ></button>
                }
            </div>
        }
        @if (!complaint && formComplaintType) {
            <div class="row">
                <jhi-complaint-form
                    class="flex-grow-1"
                    [exercise]="exercise"
                    [resultId]="result!.id!"
                    [examId]="exam?.id!"
                    [complaintType]="formComplaintType"
                    [isCurrentUserSubmissionAuthor]="isCorrectUserToFileAction"
                    (onSubmit)="loadPotentialComplaint()"
                />
            </div>
        }
        @if (complaint) {
            <div class="row">
                <jhi-complaint-request class="col-12 col-md-6" [complaint]="complaint" [maxComplaintTextLimit]="course?.maxComplaintTextLimit!" />
                <jhi-complaint-response class="col-12 col-md-6" [complaint]="complaint" [maxComplaintResponseTextLimit]="course?.maxComplaintResponseTextLimit!" />
            </div>
        }
    </div>
}
